import React, { Children } from "react";
import loadable from "@loadable/component";

import BaseAdmin from "../Admin/Layout/BaseAdmin";
import AdminRouteProvider from "./RouteProvider/AdminRouteProvider";
import AssetConfig from "../Admin/Components/Setting/Asset/AssetConfig";
import GatewayConfig from "../Admin/Components/Setting/Gateway/GatewayConfig";
import LoyaltyConfig from "../Admin/Components/PackBoosterOrder/LoyaltyConfig";
import ConfigAsset from "../Admin/Components/Assets/ConfigAsset/ConfigAsset";
import AdminCompta from "../Admin/Components/Wallets/Admincompta";
import AdminFinancial from "../Admin/Components/Wallets/AdminFinancial";
import { channel } from "diagnostics_channel";
import path from "path";
import { AllowedCountry } from "../Services/FidelityService/FidelityService";

const DashboardAdmin = loadable(
    () => import("../Admin/Components/Dashboard/DashboardAdmin"),
);

const ListMerchant = loadable(
    () => import("../Admin/Components/Roles/Merchant/ListMerchant"),
);
const ListAdvisor = loadable(
    () => import("../Admin/Components/Roles/Advisor/ListAdvisor"),
);

const FidelityNew = loadable(
    () => import("../Admin/Components/Fidelity/FidelityNew"),
);
const GovernanceNew = loadable(
    () => import("../Admin/Components/Governance/GovernanceNew"),
);
const GovernanceList = loadable(
    () => import("../Admin/Components/Governance/GovernanceList"),
);
const AssetsEarningNew = loadable(
    () =>
        import("../Admin/Components/Assets/DragNdropEarning/AssetsEarningNew"),
);
const ListAssetsEarning = loadable(
    () => import("../Admin/Components/Assets/ListAssetsEarning"),
);
const VirementConfig = loadable(
    () => import("../Admin/Components/Setting/Gateway/Virement/VirementConfig"),
);

const NavigateFidelityList = loadable(
    () => import("../Admin/Components/Fidelity/NavigateFidelityList"),
);

const ListFidelity = loadable(
    () => import("../Admin/Components/Fidelity/List/FidelityList"),
);

const AdminWallets = loadable(
    () => import("../Admin/Components/Wallets/AdminWallets"),
);

const AdminListTransactions = loadable(
    () => import("../Admin/Components/Transactions/ListTransactions"),
);

const AssetsTable = loadable(
    () => import("../Admin/Components/Assets/AssetsTable"),
);

const AssetsDragDrop = loadable(
    () => import("../Admin/Components/Assets/DragNdrop/AssetsDragDrop"),
);

const AdminListStackings = loadable(
    () => import("../Admin/Components/Stacking/ListStackings"),
);

const CryptoCategoryNew = loadable(
    () => import("../Admin/Components/Assets/Category/CryptoCategoryNew"),
);

const ListCryptoCategory = loadable(
    () => import("../Admin/Components/Assets/Category/ListCryptoCategory"),
);

const CardAdmin = loadable(() => import("../Admin/Components/Cards/CardAdmin"));

const ListUser = loadable(
    () => import("../Admin/Components/User/List/ListUser"),
);

const ListAssets = loadable(
    () => import("../Admin/Components/Assets/ListAssets"),
);
const AssetsNew = loadable(
    () => import("../Admin/Components/Assets/AssetsNew"),
);

const ProductNew = loadable(
    () => import("../Admin/Components/Product/Product/ProductNew"),
);

const ListProducts = loadable(
    () => import("../Admin/Components/Product/Product/ListProducts"),
);

const ProductCategoryNew = loadable(
    () => import("../Admin/Components/Product/Category/ProductCategoryNew"),
);

const ListProductCategory = loadable(
    () => import("../Admin/Components/Product/Category/ListProductCategory"),
);

const ListUnilevels = loadable(
    () => import("../Admin/Components/Unilevel/ListUnilevel"),
);

const UnilevelNew = loadable(
    () => import("../Admin/Components/Unilevel/UnilevelNew"),
);

const ListRank = loadable(() => import("../Admin/Components/Rank/ListRank"));

const RankNew = loadable(() => import("../Admin/Components/Rank/RankNew"));

const ListDuration = loadable(
    () => import("../Admin/Components/Setting/Asset/Duration/ListDuration"),
);
const TransactionForm = loadable(
    () => import("../Admin/Components/User/List/TransactionForm"),
);

const UserStakingForm = loadable(
    () => import("../Admin/Components/User/Staking/UserStakingForm"),
);

const PackBoosterAdminComponent = loadable(
    () => import("../Admin/Components/User/PackBooster/PackBooster"),
);

const ListWithdrawalRequest = loadable(
    () => import("../Admin/Components/WithdrawalRequest/ListWithdrawalRequest"),
);

const KycSetting = loadable(
    () => import("../Admin/Components/Setting/Kyc/Kyc"),
);

// setting

const FiatSetting = loadable(
    () => import("../Admin/Components/Setting/Asset/Fiat/Fiat"),
);
const KNTSetting = loadable(
    () => import("../Admin/Components/Setting/Asset/KNT/KNT"),
);
const SwapSetting = loadable(
    () => import("../Admin/Components/Setting/Asset/Swap/Swap"),
);
const TransferSetting = loadable(
    () => import("../Admin/Components/Setting/Asset/Transfer/Transfer"),
);
const CryptoConfig = loadable(
    () => import("../Admin/Components/Setting/Gateway/Crypto/CryptoConfig"),
);
const KalypayConfig = loadable(
    () => import("../Admin/Components/Setting/Gateway/Kalypay/KalypayConfig"),
);
const VisaConfig = loadable(
    () => import("../Admin/Components/Setting/Gateway/Visa/VisaConfig"),
);

const DepositTransaction = loadable(
    () => import("../Admin/Components/DepositTransactions/List"),
);

const ManualDepositTransaction = loadable(
    () => import("../Admin/Components/ManualDepositTransactions/List"),
);

const ListPools = loadable(() => import("../Admin/Components/Pools/ListPools"));

const PoolNew = loadable(() => import("../Admin/Components/Pools/PoolNew"));

const NoticeList = loadable(
    () => import("../Admin/Components/Notice/NoticeList"),
);

const NoticeNew = loadable(
    () => import("../Admin/Components/Notice/NoticeNew"),
);

const RoiTable = loadable(
    () => import("../Admin/Components/RoiTransactions/RoiTable"),
);

const SupportList = loadable(
    () => import("../Admin/Components/Support/SupportList"),
);

const SupportForm = loadable(
    () => import("../Admin/Components/Support/SupportNew"),
);

const AdminWalletsAccounting = loadable(
    () => import("../Admin/Components/Wallets/AdminWalletsAccounting"),
);

const HighNoticeList = loadable(
    () => import("../Admin/Components/HighNotice/HighNoticeList"),
);

const HighNoticeNew = loadable(
    () => import("../Admin/Components/HighNotice/HighNoticeNew"),
);

const UserLoalty = loadable(
    () => import("../Admin/Components/PackBoosterOrder/UserLoality"),
);
const Fidelity = loadable(
    () => import("../Admin/Components/PackBoosterOrder/Fidelity"),
);
const ListFiat = loadable(() => import("../Admin/Components/Fiat/ListFiat"));
const NewFiat = loadable(() => import("../Admin/Components/Fiat/NewFiat"));
const ListCurrency = loadable(
    () => import("../Admin/Components/BasicData/Currency/CurrencyList"),
);
const NewCurrency = loadable(
    () => import("../Admin/Components/BasicData/Currency/NewCurrency"),
);
const PerfectMoneyConfig = loadable(
    () =>
        import(
            "../Admin/Components/Setting/Gateway/PerfectMoney/PerfectMoneyConfig"
        ),
);
const PayeerConfig = loadable(
    () => import("../Admin/Components/Setting/Gateway/Payeer/PayeerConfig"),
);
const MobileMoneyConfig = loadable(
    () =>
        import(
            "../Admin/Components/Setting/Gateway/MobileMoney/MobileMoneyConfig"
        ),
);
const GPTConfig = loadable(
    () => import("../Admin/Components/Setting/Gateway/GPT/GPTConfig"),
);
const Moderation = loadable(
    () => import("../Admin/Components/Moderation/Moderations"),
);
const SubAccountRequest = loadable(
    () => import("../Admin/Components/SubAccountRequest/SubAccountRequest"),
);
const GeneralSetting = loadable(
    () => import("../Admin/Components/Setting/General/General"),
);
const GeneralConfig = loadable(
    () => import("../Admin/Components/Setting/General/GeneralConfig"),
);
const CountryBlackList = loadable(
    () => import("../Admin/Components/Setting/General/CountryBlacklist"),
);
const PersoAccount = loadable(
    () => import("../Admin/Components/Setting/General/PersoAccount"),
);
const OrderCardTabs = loadable(
    () => import("../Admin/Components/OrderCard/OrderCardTabs"),
);
const OrderCardAdmin = loadable(
    () => import("../Admin/Components/OrderCard/OrderCardAdmin"),
);

const ListUserRoles = loadable(
    () => import("../Admin/Components/Roles/Compatibility-roles/ListUserRoles"),
);

const AdminRoutes = [
    {
        element: <AdminRouteProvider />,
        children: [
            {
                element: <BaseAdmin />,
                path: "/admin",
                children: [
                    {
                        path: "dashboard",
                        element: <DashboardAdmin />,
                    },
                    {
                        path: "merchant",
                        element: <ListMerchant />,
                    },
                    // {
                    //     path: "advisor",
                    //     element: <ListAdvisor />,
                    // },
                    {
                        path: "roles",
                        element: <ListUserRoles />,
                    },
                    {
                        path: "wallet-detail",
                        element: <AdminWallets />,
                    },
                    {
                        path: "accounting",
                        element: <AdminCompta />,
                    },
                    {
                        path: "financial",
                        element: <AdminFinancial />,
                    },
                    {
                        path: "savings",
                        element: <AdminListStackings />,
                    },
                    {
                        path: "card",
                        element: <CardAdmin />,
                    },
                    {
                        path: "user",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <ListUser />,
                            },
                            {
                                path: "transact/:id",
                                element: <TransactionForm />,
                            },
                            {
                                path: "stake/:user_id",
                                element: <UserStakingForm />,
                            },
                            {
                                path: "booster/:uid",
                                element: <PackBoosterAdminComponent />,
                            },
                        ],
                    },
                    {
                        path: "deposit",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <DepositTransaction />,
                            },
                        ],
                    },
                    {
                        path: "deposit-request",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <ManualDepositTransaction />,
                            },
                        ],
                    },
                    {
                        path: "staking-roi",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <RoiTable />,
                            },
                        ],
                    },
                    {
                        path: "assets",
                        children: [
                            {
                                element: <ListAssets />,
                                children: [
                                    {
                                        path: "list",
                                        element: <AssetsTable />,
                                    },
                                    {
                                        path: "rearrange",
                                        element: <AssetsDragDrop />,
                                    },
                                ],
                            },
                            {
                                path: "new",
                                element: <AssetsNew />,
                            },
                            {
                                path: "edit/:id",
                                element: <AssetsNew />,
                            },
                            {
                                path: "config/:abbr",
                                element: <ConfigAsset />,
                            },
                        ],
                    },
                    {
                        path: "Fiat",
                        children: [
                            {
                                path: "",
                                element: <ListFiat />,
                            },
                            {
                                path: "new",
                                element: <NewFiat />,
                            },
                            {
                                path: "edit/:id",
                                element: <NewFiat />,
                            },
                            // {
                            //     path: "config/:abbr",
                            //     element: <ConfigFiat />,
                            // },
                        ],
                    },
                    {
                        path: "currency",
                        children: [
                            {
                                path: "",
                                element: <ListCurrency />,
                            },
                            {
                                path: "new",
                                element: <NewCurrency />,
                            },
                            {
                                path: "edit/:id",
                                element: <NewCurrency />,
                            },
                        ],
                    },
                    {
                        path: "assets-earning",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <ListAssetsEarning />,
                            },
                            {
                                path: "configure/:id",
                                element: <AssetsEarningNew />,
                            },
                        ],
                    },
                    {
                        path: "pools",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <ListPools />,
                            },
                            {
                                path: "new",
                                element: <PoolNew />,
                            },
                            {
                                path: "edit/:id",
                                element: <PoolNew />,
                            },
                        ],
                    },
                    {
                        path: "all-transactions",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <AdminListTransactions />,
                            },
                        ],
                    },
                    {
                        path: "withdrawal-request",
                        element: <ListWithdrawalRequest />,
                    },
                    {
                        path: "config",
                        element: <NavigateFidelityList />,
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <ListFidelity />,
                            },

                            {
                                path: "config-subscription",
                                children: [
                                    {
                                        index: true,
                                        path: "",
                                        element: <ListFidelity />,
                                    },
                                    {
                                        path: "new",
                                        element: <FidelityNew />,
                                    },
                                    {
                                        path: "edit/:id",
                                        element: <FidelityNew />,
                                    },
                                ],
                            },
                            {
                                path: "config-world",
                                children: [
                                    {
                                        index: true,
                                        path: "",
                                        element: (
                                            <ListFidelity
                                                type={AllowedCountry.WORLD}
                                            />
                                        ),
                                    },
                                    {
                                        path: "new",
                                        element: (
                                            <FidelityNew
                                                type={AllowedCountry.WORLD}
                                            />
                                        ),
                                    },
                                    {
                                        path: "edit/:id",
                                        element: (
                                            <FidelityNew
                                                type={AllowedCountry.WORLD}
                                            />
                                        ),
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: "subscription-orders",
                        element: <LoyaltyConfig />,
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <Fidelity />,
                            },
                            {
                                path: "orders-eu",
                                children: [
                                    // {
                                    //     path: "users",
                                    //     // index: true,
                                    //     element: <UserLoalty />,
                                    // },
                                    {
                                        // path: "fidelities",
                                        index: true,
                                        path: "",
                                        element: <Fidelity />,
                                    },
                                ],
                            },
                            {
                                path: "orders-world",
                                children: [
                                    // {
                                    //     path: "users",
                                    //     index: true,
                                    //     element: <UserLoalty />,
                                    // },
                                    {
                                        // path: "fidelities",
                                        path: "",
                                        element: (
                                            <Fidelity
                                                type={AllowedCountry.WORLD}
                                            />
                                        ),
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        path: "product",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <ListProducts />,
                            },
                            {
                                path: "new",
                                element: <ProductNew />,
                            },
                            {
                                path: "edit/:id",
                                element: <ProductNew />,
                            },
                        ],
                    },

                    {
                        path: "product-category",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <ListProductCategory />,
                            },
                            {
                                path: "new",
                                element: <ProductCategoryNew />,
                            },
                            {
                                path: "edit/:id",
                                element: <ProductCategoryNew />,
                            },
                        ],
                    },

                    {
                        path: "crypto-category",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <ListCryptoCategory />,
                            },
                            {
                                path: "new",
                                element: <CryptoCategoryNew />,
                            },
                            {
                                path: "edit/:id",
                                element: <CryptoCategoryNew />,
                            },
                        ],
                    },
                    {
                        path: "referrals",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <ListUnilevels />,
                            },
                            {
                                path: "new",
                                element: <UnilevelNew />,
                            },
                            {
                                path: "edit/:id",
                                element: <UnilevelNew />,
                            },
                        ],
                    },
                    {
                        path: "rank",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <ListRank />,
                            },
                            {
                                path: "new",
                                element: <RankNew />,
                            },
                            {
                                path: "edit/:id",
                                element: <RankNew />,
                            },
                        ],
                    },
                    {
                        path: "setting",
                        children: [
                            {
                                path: "general",
                                element: <GeneralConfig />,
                                children: [
                                    {
                                        path: "",
                                        element: <GeneralSetting />,
                                    },
                                    {
                                        path: "general",
                                        element: <GeneralSetting />,
                                    },
                                    {
                                        path: "country-blacklist",
                                        element: <CountryBlackList />,
                                    },
                                    {
                                        path: "perso-account",
                                        element: <PersoAccount />,
                                    },
                                ],
                            },
                            {
                                path: "asset",
                                element: <AssetConfig />,
                                children: [
                                    {
                                        path: "",
                                        index: true,
                                        element: <KNTSetting />,
                                    },
                                    {
                                        path: "infinity",
                                        index: true,
                                        element: <KNTSetting />,
                                    },
                                    {
                                        path: "fiat",
                                        element: <FiatSetting />,
                                    },
                                    {
                                        path: "currency",
                                        // index: true,
                                        children: [
                                            {
                                                path: "",
                                                element: <ListCurrency />,
                                            },
                                            {
                                                path: "new",
                                                element: <NewCurrency />,
                                            },
                                            {
                                                path: "edit/:id",
                                                element: <NewCurrency />,
                                            },
                                        ],
                                    },
                                    {
                                        path: "swap",
                                        element: <SwapSetting />,
                                    },
                                    {
                                        path: "transfer",
                                        element: <TransferSetting />,
                                    },
                                ],
                            },
                            {
                                path: "kyc",
                                element: <KycSetting />,
                            },
                            {
                                path: "duration",
                                element: <ListDuration />,
                            },
                            {
                                path: "payment",
                                element: <GatewayConfig />,
                                children: [
                                    {
                                        element: <VisaConfig />,
                                        path: "",
                                        index: true,
                                    },
                                    {
                                        element: <KalypayConfig />,
                                        path: "kalypay",
                                        index: true,
                                    },
                                    {
                                        element: <VisaConfig />,
                                        path: "visa",
                                        index: true,
                                    },
                                    {
                                        element: <CryptoConfig />,
                                        path: "crypto",
                                        index: true,
                                    },
                                    {
                                        element: <VirementConfig />,
                                        path: "virement",
                                        index: true,
                                    },
                                    {
                                        element: <MobileMoneyConfig />,
                                        path: "mobile-money",
                                        index: true,
                                    },
                                    {
                                        element: <PerfectMoneyConfig />,
                                        path: "perfect-money",
                                        index: true,
                                    },
                                    {
                                        element: <PayeerConfig />,
                                        path: "payeer",
                                        index: true,
                                    },
                                    {
                                        element: <GPTConfig />,
                                        path: "GPT",
                                        index: true,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: "notice",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <NoticeList />,
                            },
                            {
                                path: "new",
                                element: <NoticeNew />,
                            },
                            {
                                path: "edit/:id",
                                element: <NoticeNew />,
                            },
                        ],
                    },
                    {
                        path: "governance",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <GovernanceList />,
                            },
                            {
                                path: "new",
                                element: <GovernanceNew />,
                            },
                            {
                                path: "edit/:id",
                                element: <GovernanceNew />,
                            },
                            {
                                path: "duplicate/:id",
                                element: <GovernanceNew />,
                            },
                        ],
                    },
                    {
                        path: "carrousel",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <HighNoticeList />,
                            },
                            {
                                path: "new",
                                element: <HighNoticeNew />,
                            },
                            {
                                path: "edit/:id",
                                element: <HighNoticeNew />,
                            },
                        ],
                    },
                    {
                        path: "support",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <SupportList />,
                            },
                            {
                                path: "new",
                                element: <SupportForm />,
                            },
                            {
                                path: "edit/:id",
                                element: <SupportForm />,
                            },
                        ],
                    },
                    {
                        path: "moderation",
                        element: <Moderation />,
                        children: [
                            // {
                            //     index: true,
                            //     path: "",
                            //     element: <Moderation />,
                            // },
                        ],
                    },
                    {
                        path: "subaccounts",
                        element: <SubAccountRequest />,
                        children: [
                            // {
                            //     index: true,
                            //     path: "",
                            //     element: <Moderation />,
                            // },
                        ],
                    },
                    {
                        path: "cards-orders",
                        element: <OrderCardTabs />,
                        children: [
                            {
                                path: "eu-cards-orders",
                                children: [
                                    {
                                        path: ":status",
                                        index: true,
                                        element: <OrderCardAdmin />,
                                    },
                                ],
                            },
                            {
                                path: "world-cards-orders",
                                children: [
                                    {
                                        path: ":status",
                                        index: true,
                                        element: (
                                            <OrderCardAdmin
                                                type={AllowedCountry.WORLD}
                                            />
                                        ),
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export default AdminRoutes;
