import React, { useEffect, useMemo, useState } from "react";
import UtilsService from "../../../Services/UtilsService";
import translator from "../../../Components/Extra/Translation/Translate";
import { useBaseCurrency } from "../../../App/Provider/BaseCurrencyProvider";
import TrendingUpSvg from "../../../Components/Extra/Svg/TrendingUpSvg";
import { AmountData } from "../../../Models/Transaction";
import TrendingDownSvg from "../../../Components/Extra/Svg/TrendingDownSvg";

const ApiGeneralReport = ({
    generalReportData,
}: {
    generalReportData: AmountData;
}) => {
    const { base: baseCurrency } = useBaseCurrency();
    const { translate } = translator();
    const {
        amount_today,
        amount_yesterday,
        sum_amount_data,
        total_fee_usd,
        percentageChange,
        user_total,
    } = useMemo(() => {
        if (generalReportData) {
            const amount_today =
                generalReportData.sum_amount_data_today.total_usd_price;
            const amount_yesterday =
                generalReportData.sum_amount_data_yesterday?.total_usd_price ??
                0;
            const sum_amount_data =
                generalReportData.sum_amount_data.total_usd_price;

            const total_fee_usd =
                generalReportData.sum_amount_data.total_fee_usd;

            // Calcul du pourcentage de changement
            const percentageChange =
                amount_yesterday !== 0
                    ? ((amount_today - amount_yesterday) / amount_yesterday) *
                      100
                    : 0;
            const user_total = generalReportData?.user_total ?? 0;

            return {
                amount_today,
                amount_yesterday,
                sum_amount_data,
                total_fee_usd,
                percentageChange,
                user_total,
            };
        }

        return {
            amount_today: 0,
            amount_yesterday: 0,
            sum_amount_data: 0,
            total_fee_usd: 0,
            percentageChange: 0,
            user_total: 0,
        };
    }, [generalReportData]);

    return (
        <div>
            <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 2xl:col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12">
                            <div className="mt-1 grid grid-cols-12 gap-6">
                                {/* Total Ventes */}
                                <div className="intro-y col-span-12 sm:col-span-6 xl:col-span-3">
                                    <div className="report-box dark:border-2 dark:border-slate-700 dark:rounded-md">
                                        <div className="box p-5">
                                            <div className="flex">
                                                <div className="ml-auto">
                                                    <div
                                                        className={`flex tooltip cursor-pointer px-0 pt-0 ${percentageChange < 0 ? " text-red-600" : " text-green-600"} text-green-600 !text-base font-bold`}
                                                    >
                                                        {percentageChange >=
                                                        0 ? (
                                                            <TrendingUpSvg />
                                                        ) : (
                                                            <TrendingDownSvg />
                                                        )}
                                                        {`${percentageChange.toFixed(
                                                            2,
                                                        )}%`}{" "}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="-mt-1 text-2xl font-medium leading-5">
                                                {amount_today && baseCurrency
                                                    ? UtilsService.getConvertedFormatedAmount(
                                                          amount_today,
                                                          baseCurrency,
                                                          2,
                                                      )
                                                    : "0,00 €"}
                                            </div>
                                            <div className="mt-1 text-base text-slate-500">
                                                {translate(
                                                    "ACCOUNTING",
                                                    "Api_Today",
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Total Chiffre d'affaire */}
                                <div className="intro-y col-span-12 sm:col-span-6 xl:col-span-3">
                                    <div className="report-box dark:border-2 dark:border-slate-700 dark:rounded-md">
                                        <div className="box p-5">
                                            <div className="mt-5 text-2xl font-medium leading-5">
                                                {sum_amount_data && baseCurrency
                                                    ? UtilsService.getConvertedFormatedAmount(
                                                          sum_amount_data,
                                                          baseCurrency,
                                                          2,
                                                      )
                                                    : "0,00 €"}
                                            </div>
                                            <div className="mt-1 text-base text-slate-500">
                                                {translate(
                                                    "Admin_Dashboard",
                                                    "Total_Sales",
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Total Bénéfices */}
                                <div className="intro-y col-span-12 sm:col-span-6 xl:col-span-3">
                                    <div className="report-box dark:border-2 dark:border-slate-700 dark:rounded-md">
                                        <div className="box p-5">
                                            <div className="mt-5 text-2xl font-medium leading-5">
                                                {generalReportData &&
                                                baseCurrency
                                                    ? UtilsService.getConvertedFormatedAmount(
                                                          sum_amount_data -
                                                              total_fee_usd,
                                                          baseCurrency,
                                                          2,
                                                      )
                                                    : "0,00 €"}
                                            </div>
                                            <div className="mt-1 text-base text-slate-500">
                                                {translate(
                                                    "Admin_Dashboard",
                                                    "Total_Profits",
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Commissions */}
                                <div className="intro-y col-spawn-12 sm:col-span-6 xl:col-span-3">
                                    <div className="report-box dark:border-2 dark:border-slate-700 dark:rounded-md">
                                        <div className="box p-5">
                                            <div className="mt-5 text-2xl font-medium leading-5">
                                                {user_total}
                                            </div>
                                            <div className="mt-1 text-base text-slate-500">
                                                {translate(
                                                    "ACCOUNTING",
                                                    "Api_Users",
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApiGeneralReport;
