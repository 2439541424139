import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import User from "../../Models/User";
import PnlModel from "../../Models/Pnl";

export interface AuthGlobalState {
    user?: User;
    need2fa: boolean;
    authLoading: boolean;
    isAuthenticated: boolean;
    userLoginLoading: boolean;
    loginError?: string;
    authChecking?: boolean;
    pnl?: PnlModel;
}

const initialState: AuthGlobalState = {
    user: undefined,
    need2fa: false,
    authLoading: false,
    isAuthenticated: false,
    userLoginLoading: false,
    loginError: undefined,
    authChecking: false,
};

const Auth = createSlice({
    name: "auth",
    initialState,
    reducers: {
        VerifyTokenStarted(state, action: PayloadAction<{ silent?: boolean }>) {
            if (!action.payload.silent) {
                state.user = undefined;
                state.isAuthenticated = false;
                state.authLoading = false;
                state.loginError = undefined;
            } else {
                state.authLoading = true;
            }
        },

        VerifyTokenEnd(state) {
            state.authLoading = false;
        },

        AuthenticationStarted(state) {
            state.userLoginLoading = true;
            state.loginError = undefined;
        },

        AuthenticationFailure(state, action: PayloadAction<{ error: string }>) {
            state.loginError = action.payload.error;
            state.userLoginLoading = false;
            state.isAuthenticated = false;
        },
        loggedWithSuccess(
            state,
            action: PayloadAction<{
                user: any;
            }>,
        ) {
            const {
                uid,
                reinvest_auto,
                hide_zero_amount,
                hide_not_active_earning,
                last_name,
                first_name,
                username,
                email,
                scopes,
                photo,
                parrain,
                haveTransactionPassword,
                cap,
                cag,
                is_active,
                is_fiat,
                type,
                entreprise,
                siret,
                currency,
                post_code,
                address,
                phone_country_code,
                phone,
                date_of_birth,
                country,
                city,
                base_currency,
                monthly_expenses_limit,
                can_use_crypto,
                can_transfert_crypto,
                can_close_earning,
                is_parent,
                tutor,
                allow_the_child_to_purchase_assets,
                allow_child_deposit_assets,
                allow_child_to_trade_assets,
                allow_the_child_to_transfer_assets,
                join_account,
                merchant_app_id,
                merchant_app_key,
                is_perso,
                merchant_enable_payment,
                merchant_enable_transfer,
                setting,
                can_create_jackpot,
                inf_app_fee,
                widget_setting,
                theme_setting,
                language,
                backgrounds,
                market_trends,
                fiats,
                merchant_payment_configuration,
            } = action.payload.user;

            const authenticatedUser = new User(
                uid,
                username,
                last_name,
                first_name,
                email,
                reinvest_auto,
                hide_zero_amount,
                country,
                language,
                backgrounds ?? [],
            );

            authenticatedUser.scopes = scopes;
            authenticatedUser.photo = photo;
            authenticatedUser.parrain = parrain;
            authenticatedUser.cag = cag;
            authenticatedUser.cap = cap;
            authenticatedUser.is_active = is_active;
            authenticatedUser.hide_not_active_earning = hide_not_active_earning;
            authenticatedUser.is_fiat = is_fiat;
            authenticatedUser.haveTransactionPassword = haveTransactionPassword;
            authenticatedUser.type = type;
            authenticatedUser.siret = siret;
            authenticatedUser.entreprise = entreprise;
            authenticatedUser.currency = currency;

            authenticatedUser.date_of_birth = date_of_birth;
            authenticatedUser.city = city;
            authenticatedUser.post_code = post_code;
            authenticatedUser.address = address;
            authenticatedUser.phone_country_code = phone_country_code;
            authenticatedUser.phone = phone;
            authenticatedUser.base_currency = base_currency;
            authenticatedUser.monthly_expenses_limit = monthly_expenses_limit;
            authenticatedUser.can_use_crypto = can_use_crypto;
            authenticatedUser.can_transfert_crypto = can_transfert_crypto;
            authenticatedUser.can_close_earning = can_close_earning;
            authenticatedUser.is_parent = is_parent;
            authenticatedUser.tutor = tutor;

            authenticatedUser.allow_the_child_to_purchase_assets =
                allow_the_child_to_purchase_assets;
            authenticatedUser.allow_child_deposit_assets =
                allow_child_deposit_assets;
            authenticatedUser.allow_child_to_trade_assets =
                allow_child_to_trade_assets;
            authenticatedUser.allow_the_child_to_transfer_assets =
                allow_the_child_to_transfer_assets;
            authenticatedUser.join_account = join_account;
            authenticatedUser.is_perso = is_perso;
            authenticatedUser.setting = setting;
            authenticatedUser.can_create_jackpot = can_create_jackpot;
            authenticatedUser.widget_setting = widget_setting;
            authenticatedUser.theme_setting = theme_setting;
            authenticatedUser.language = language;
            authenticatedUser.market_trends = market_trends;
            authenticatedUser.fiats = fiats;

            if (scopes.includes("merchant")) {
                authenticatedUser.merchant_app_id = merchant_app_id;
                authenticatedUser.merchant_app_key = merchant_app_key;
                authenticatedUser.merchant_enable_payment =
                    merchant_enable_payment;
                authenticatedUser.merchant_enable_transfer =
                    merchant_enable_transfer;
                authenticatedUser.inf_app_fee = inf_app_fee;
                authenticatedUser.merchant_payment_configuration =
                    merchant_payment_configuration;
            }

            state.user = authenticatedUser;
            state.isAuthenticated = true;
            state.authLoading = false;
            state.userLoginLoading = false;
            if (typeof localStorage != "undefined") {
                localStorage.setItem("AccountConnected", "yes");
            }
        },
        verifyUserSuccess(state) {
            state.authLoading = false;
            state.userLoginLoading = false;
        },
        Logout(state) {
            state.user = undefined;
            state.isAuthenticated = false;
            state.authLoading = false;
            state.loginError = undefined;
            state.need2fa = false;
            if (typeof localStorage != "undefined")
                localStorage.setItem("AccountConnected", "false");
        },
        StartAuthChecking(state) {
            state.authChecking = true;
        },
        EndAuthChecking(state) {
            state.authChecking = false;
        },
        ChangeTwoFANeeded(
            state,
            action: PayloadAction<{
                two_fa_needed: boolean;
            }>,
        ) {
            state.need2fa = action.payload.two_fa_needed;
        },
        UpdateUserInformation(
            state,
            action: PayloadAction<{
                user: any;
            }>,
        ) {
            const {
                uid,
                reinvest_auto,
                hide_zero_amount,
                hide_not_active_earning,
                last_name,
                first_name,
                username,
                email,
                scopes,
                photo,
                parrain,
                haveTransactionPassword,
                cap,
                cag,
                is_active,
                is_fiat,
                type,
                entreprise,
                siret,
                currency,
                post_code,
                address,
                phone_country_code,
                phone,
                country,
                date_of_birth,
                city,
                base_currency,
                monthly_expenses_limit,
                can_use_crypto,
                can_transfert_crypto,
                can_close_earning,
                is_parent,
                tutor,
                allow_the_child_to_purchase_assets,
                allow_child_deposit_assets,
                allow_child_to_trade_assets,
                allow_the_child_to_transfer_assets,
                join_account,
                merchant_app_id,
                merchant_app_key,
                is_perso,
                merchant_enable_payment,
                merchant_enable_transfer,
                setting,
                can_create_jackpot,
                inf_app_fee,
                widget_setting,
                theme_setting,
                language,
                backgrounds,
                market_trends,
                fiats,
                merchant_payment_configuration,
            } = action.payload.user;

            const authenticatedUser = new User(
                uid,
                username,
                last_name,
                first_name,
                email,
                reinvest_auto,
                hide_zero_amount,
                country,
                language,
                backgrounds ?? [],
            );

            authenticatedUser.scopes = scopes;
            authenticatedUser.photo = photo;
            authenticatedUser.parrain = parrain;
            authenticatedUser.cag = cag;
            authenticatedUser.cap = cap;
            authenticatedUser.is_active = is_active;
            authenticatedUser.hide_not_active_earning = hide_not_active_earning;
            authenticatedUser.is_fiat = is_fiat;
            authenticatedUser.haveTransactionPassword = haveTransactionPassword;
            authenticatedUser.type = type;
            authenticatedUser.siret = siret;
            authenticatedUser.entreprise = entreprise;

            authenticatedUser.date_of_birth = date_of_birth;

            authenticatedUser.city = city;
            authenticatedUser.post_code = post_code;
            authenticatedUser.address = address;
            authenticatedUser.phone_country_code = phone_country_code;
            authenticatedUser.phone = phone;
            authenticatedUser.base_currency = base_currency;
            authenticatedUser.currency = currency;
            authenticatedUser.monthly_expenses_limit = monthly_expenses_limit;
            authenticatedUser.can_use_crypto = can_use_crypto;
            authenticatedUser.can_transfert_crypto = can_transfert_crypto;
            authenticatedUser.can_close_earning = can_close_earning;
            authenticatedUser.is_parent = is_parent;
            authenticatedUser.tutor = tutor;
            authenticatedUser.allow_the_child_to_purchase_assets =
                allow_the_child_to_purchase_assets;
            authenticatedUser.allow_child_deposit_assets =
                allow_child_deposit_assets;
            authenticatedUser.allow_child_to_trade_assets =
                allow_child_to_trade_assets;
            authenticatedUser.allow_the_child_to_transfer_assets =
                allow_the_child_to_transfer_assets;
            authenticatedUser.join_account = join_account;
            authenticatedUser.is_perso = is_perso;
            authenticatedUser.setting = setting;
            authenticatedUser.can_create_jackpot = can_create_jackpot;
            authenticatedUser.widget_setting = widget_setting;
            authenticatedUser.theme_setting = theme_setting;
            authenticatedUser.language = language;
            authenticatedUser.market_trends = market_trends;
            authenticatedUser.fiats = fiats;
            if (scopes.includes("merchant")) {
                authenticatedUser.merchant_app_id = merchant_app_id;
                authenticatedUser.merchant_app_key = merchant_app_key;
                authenticatedUser.merchant_enable_payment =
                    merchant_enable_payment;
                authenticatedUser.merchant_enable_transfer =
                    merchant_enable_transfer;
                authenticatedUser.inf_app_fee = inf_app_fee;
                authenticatedUser.merchant_payment_configuration =
                    merchant_payment_configuration;
            }

            state.user = authenticatedUser;
            state.isAuthenticated = true;
            localStorage.setItem("AccountConnected", "yes");
        },
        UpdateUserPrincipalBalance(
            state,
            action: PayloadAction<{
                solde_principal: number;
                blocked: number;
                available: number;
            }>,
        ) {
            if (state.user) {
                state.user.solde_principal = action.payload.solde_principal;
                state.user.blocked = action.payload.blocked;
                state.user.available = action.payload.available;
            }
        },
        UpdateUserPnlData(
            state,
            action: PayloadAction<{
                pnl: PnlModel;
            }>,
        ) {
            state.pnl = action.payload.pnl;
        },
    },
});

export const {
    VerifyTokenStarted,
    VerifyTokenEnd,
    AuthenticationStarted,
    AuthenticationFailure,
    Logout,
    verifyUserSuccess,
    loggedWithSuccess,
    StartAuthChecking,
    EndAuthChecking,
    UpdateUserInformation,
    ChangeTwoFANeeded,
    UpdateUserPrincipalBalance,
    UpdateUserPnlData,
} = Auth.actions;

export default Auth.reducer;
